import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
library.add(faCheck)

import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'

const API_PATH = '/api/contact/index.php'

const recaptchaRef = React.createRef()

export default class ContactForm extends Component {
  state = {
    formData: {
      fname: '',
      subject: '',
      email: '',
      message: '',
    },
    sending: false,
    mailSent: false,
    error: null,
  }

  handleFormSubmit = event => {
    event.preventDefault()
    event.target.reportValidity()
    recaptchaRef.current.execute()

    this.setState({ sending: true })

    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: this.state.formData,
    })
      .then(result => {
        this.setState({
          formData: {
            fname: '',
            subject: '',
            email: '',
            message: '',
          },
          mailSent: result.data.sent,
          sending: false,
        })
      })
      .catch(error => this.setState({ error: error.message, sending: false }))
  }

  handleFormInput = e => {
    const { id, value } = e.target
    this.setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [id]: value,
      },
    }))
  }

  render() {
    return (
      <Form onSubmit={e => this.handleFormSubmit(e)}>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey="6Lf_2pwUAAAAAGYPgoP2r0VuuegqBZztYi13vuGC"
        />
        <Form.Group controlId="fname">
          <Form.Label>Imię</Form.Label>
          <Form.Control
            type="text"
            value={this.state.fname}
            onChange={e => this.handleFormInput(e)}
            required
          />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>E-mail</Form.Label>
          <Form.Control
            type="email"
            value={this.state.email}
            onChange={e => this.handleFormInput(e)}
            required
          />
        </Form.Group>
        <Form.Group controlId="subject">
          <Form.Label>Temat</Form.Label>
          <Form.Control
            type="text"
            value={this.state.subject}
            onChange={e => this.handleFormInput(e)}
            required
          />
        </Form.Group>
        <Form.Group controlId="message">
          <Form.Label>Wiadomość</Form.Label>
          <Form.Control
            as="textarea"
            rows="5"
            value={this.state.message}
            onChange={e => this.handleFormInput(e)}
            required
          />
        </Form.Group>

        <Button
          variant={this.state.mailSent ? 'success' : 'primary'}
          type="submit"
          disabled={this.state.mailSent}
        >
          {this.state.sending ? (
            'Wysyłanie...'
          ) : this.state.mailSent ? (
            <span>
              <FontAwesomeIcon icon="check" /> Wysłano
            </span>
          ) : (
            'Wyślij wiadomość'
          )}
        </Button>
      </Form>
    )
  }
}

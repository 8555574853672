import React, { Component } from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'

const mapStyles = {
  width: '100%',
  height: '500px',
  marginTop: '50px',
  marginBottom: '50px',
  left: '0',
}

export class MapContainer extends Component {
  render() {
    return (
      <div
        style={{
          position: 'relative',
          height: '550px',
          marginBottom: '50px',
        }}
      >
        <Map
          google={this.props.google}
          zoom={17}
          style={mapStyles}
          initialCenter={{
            lat: 50.27161,
            lng: 19.019682,
          }}
        >
          <Marker name="Promotax" />
        </Map>
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAEv1GiCKwSdwbos9n2D0mHqZQPAPa9mo8',
})(MapContainer)

import React from 'react'

import Layout from '../components/Layout'
import Meta from '../components/Meta'
import ContactForm from '../components/ContactForm/ContactForm'
import GoogleMap from '../components/Map/Map'
import Icon from '../components/Icon/index'

import { siteMetadata } from '../../gatsby-config'

const Kontakt = () => {
  return (
    <Layout>
      <Meta site={siteMetadata} title="Kontakt" />
      <div className="container">
        <div className="row">
          <div className="col-12 my-5">
            <h1 className="text-center">Kontakt</h1>
          </div>
          <div className="col-12 col-md-6">
            <p>
              Biuro Rachunkowe PROMO&nbsp;TAX&nbsp;SP.&nbsp;Z&nbsp;O.O. <br />
              Ul. Misjonarzy Oblatów NMP 20A <br />
              40-129 Katowice <br />
              NIP: 6342859579
            </p>
            <p>
              E-mail: <a href="mailto:biuro@promotax.pl">biuro@promotax.pl</a>
              <br />
              tel. <a href="tel:+48794554420">+48 794 55 44 20</a><br/>
              <a className="fb-link" href="https://www.facebook.com/promotaxbiurorachunkowe/">
                <Icon name="facebook"/>
              </a>
            </p>
            <p>
              <u>Godziny pracy:</u>
              <br /> poniedziałek – piątek: <b>8:00 – 16:00</b>
            </p>
          </div>
          <div className="col-12 col-md-6">
            <ContactForm />
          </div>
        </div>
      </div>
      <GoogleMap />
    </Layout>
  )
}

export default Kontakt
